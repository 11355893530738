var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-card',{staticStyle:{"margin-bottom":"20px"}},[_c('search-filter',{attrs:{"searchName":_vm.activeName==='study'?'搜索学生':'搜索课程',"keywordOptions":{placeholder:_vm.activeName==='study'?'搜索学生姓名':'搜索课程名称'},"otherOptions":_vm.activeName==='study'?[]:[
            { name:'el-form-item' ,
                // options:{label:'选择老师' , prop:'teach_id', type:'select',data:{ url:'/xapi/user/teachList', labelOptions:{value:'id',label:'showname'} }}
                options:{label:'选择老师' , prop:'teach_id', type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}
            },
            { name:'el-form-item' ,
                options:{label:'状态' , prop:'status', type:'select' , placeholder:'请选择时间',data:[{label:'进行中',value:0},{label:'未开始',value:1},{label:'已结束',value:-1}]}
            }
        ],"total":_vm.total},on:{"search":function($event){return _vm.$refs.table.reset(...arguments)}},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}})],1),_c('div',{staticClass:"operate",staticStyle:{"margin-bottom":"15px"}},[_c('el-button-group',[_c('el-button',{attrs:{"type":"primary","plain":_vm.activeName !== 'study'},on:{"click":function($event){return _vm.handleTabClick('study')}}},[_vm._v("学生学习进度")]),_c('el-button',{attrs:{"type":"primary","plain":_vm.activeName !== 'class'},on:{"click":function($event){return _vm.handleTabClick('class')}}},[_vm._v("班课教学进度")])],1)],1),_c('el-card',{staticClass:"table-box"},[_c('com-table',{ref:"table",attrs:{"data":{
          url:'/xapi/user.group/courseList',
          params:{team_id: _vm.team_id,type:_vm.activeName}
        },"columns":_vm.columnAlls[_vm.activeName] || [],"where":_vm.where,"total":_vm.total},on:{"update:total":function($event){_vm.total=$event}},scopedSlots:_vm._u([{key:"action",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$handleRoute({study_user_id:scope.row.id},'TeamCourseStudySpeed')}}},[_vm._v("详情")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }