<template>
  <div>
    <el-card  style="margin-bottom: 20px;">
      <search-filter
          :searchName="activeName==='study'?'搜索学生':'搜索课程'"
          :keywordOptions="{placeholder:activeName==='study'?'搜索学生姓名':'搜索课程名称'}"
          :otherOptions="activeName==='study'?[]:[
              { name:'el-form-item' ,
                  // options:{label:'选择老师' , prop:'teach_id', type:'select',data:{ url:'/xapi/user/teachList', labelOptions:{value:'id',label:'showname'} }}
                  options:{label:'选择老师' , prop:'teach_id', type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}
              },
              { name:'el-form-item' ,
                  options:{label:'状态' , prop:'status', type:'select' , placeholder:'请选择时间',data:[{label:'进行中',value:0},{label:'未开始',value:1},{label:'已结束',value:-1}]}
              }
          ]"
          v-model="where"
          :total="total"
          @search="$refs.table.reset(...arguments)"
      ></search-filter>
    </el-card>

    <div class="operate" style="margin-bottom: 15px">
      <el-button-group>
        <el-button type="primary" :plain="activeName !== 'study'" @click="handleTabClick('study')">学生学习进度</el-button>
        <el-button type="primary" :plain="activeName !== 'class'" @click="handleTabClick('class')">班课教学进度</el-button>
      </el-button-group>
    </div>
    <el-card class="table-box">
      <com-table
          ref="table"
          :data="{
            url:'/xapi/user.group/courseList',
            params:{team_id,type:activeName}
          }"
          :columns="columnAlls[activeName] || []"
          :where="where"
          :total.sync="total"
      >
        <template slot="action" slot-scope="scope">
          <!-- <el-button @click="$handleRoute({study_user_id:scope.row.id},'teachingCourseStudySpeed')" type="text" size="small">详情</el-button> -->
          <el-button @click="$handleRoute({study_user_id:scope.row.id},'TeamCourseStudySpeed')" type="text" size="small">详情</el-button>
        </template>
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
export default {
  props: {
    team_id:{
      default: () => 0,
    },
    teamInfo:{
      type:Object,
      default: () => { return {}; },
    },
  },
  components: {SearchFilter},
  data(){
    return {
      total:0,
      where:{},
      columnAlls:{
        study:[
          {prop:'member_name',label:'学生姓名'},
          {prop:'course_count',label:'学习课程数',align:'center'},
          {prop:'complete_course_count',label:'已完成课程数',align:'center'},
          {prop:'study_course_count',label:'正在学习课程数',align:'center'},
          {prop:'start_date',label:'开始学习时间',align:'center'},
          {prop:'total_duration',label:'总学习时长',align:'center'},
          {fixed:'right',label:'操作',width:'60' , slot:'action' , align:'center'},
        ],
        class:[
          {prop:'course_name',label:'班课名称',align:'center'},
          {prop:'teach_name',label:'班课教师',align:'center'},
          {prop:'statusStr',label:'状态',align:'center'},
          {prop:'study_people',label:'已学习人数' ,align:'center'},
          // {prop:'fast_study',label:'最快学习进度',width:'120',align:'center'},
          {prop:'all_unit_num',label:'<span><font style="color:green;">开放章节</font>/<font style="color:red;">全部章节</font></span>',template:(scope)=>{
            return '<font style="color:green;">'+scope.row.open_unit_num+'</font>/<font style="color:red;">'+scope.row.all_unit_num+'</font>'
          },width:'200',align:'center'},
          {prop:'all_unit_num',label:'<span><font style="color:green;">开放条目</font>/<font style="color:red;">全部条目</font></span>',template:(scope)=>{
            return '<font style="color:green;">'+scope.row.open_list_num+'</font>/<font style="color:red;">'+scope.row.all_list_num+'</font>'
          },width:'200',align:'center'},
          // {prop:'slow_study',label:'最慢学习进度',width:'120',align:'center'},
          // {prop:'avg_study',label:'平均学习进度',width:'180',align:'center' },
          {prop:'task_count',label:'作业布置次数',align:'center'},
          // {prop:'z_count',label:'全对',width:'90',align:'center' },
          // {prop:'j_count',label:'及格',width:'90',align:'center' },
          // {prop:'n_count',label:'不及格',width:'90',align:'center'},
        ],
      },
      activeName:'study',
    }
  },
  methods: {
    routeUpdate(){
      this.where = {}
      let{b} = this.$route.query
      this.activeName = b || 'study'
    },
    handleTabClick(tab){
      this.activeName = tab;
      this.$handleRoute({b:this.activeName})
    },
    // handleClick(row) {
    //   console.log(row);
    // },
  },
}
</script>

<style lang="less" scoped>

/deep/ .table-box{
  .el-card__header{
    padding: 0;
  }
  .title{
    height:48px;
    line-height: 24px;
  }
  .price{
    font-size: 90%;
    line-height: 1.5;
    color: rgba(254,81,52,1);
  }
  .desc{
    margin-top: 10px;
    font-size: 90%;
    line-height: 1.5;
    color: rgb(136, 136, 136);
  }
}
</style>